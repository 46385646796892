<template>
  <div class="flex h-screen w-full items-center justify-center">
    <Maintenance v-if="!isEnabledForgotPassword" />
    <div
      v-else
      class="h-full w-full bg-hero bg-bottom bg-no-repeat bg-cover"
    >
      <div class="h-full relative top-0 bg-pattern bg-left-top bg-no-repeat bg-contain">
        <header
          data-cy="forgot-password__header"
          class="flex justify-center items-center h-20 bg-black bg-opacity-10 backdrop-filter backdrop-blur-lg"
        >
          <nav
            ref="navigation"
            data-cy="forgot-password__header-nav-container"
            class="container mx-auto px-6 flex justify-between items-center"
          >
            <img
              ref="navigation-logo"
              data-cy="forgot-password__header-nav-logo"
              src="@/assets/logo.svg"
              alt="Portal Jabar Logo"
              width="125"
            >
            <a
              ref="navigation-link"
              data-cy="forgot-password__header-nav-link"
              :href="portalJabarUrl"
              target="_blank"
              rel="noreferrer"
              class="flex items-center gap-3 text-white"
            >
              Ke Beranda
              <HomeIcon class="w-5 h-5 fill-white" />
            </a>
          </nav>
        </header>
        <main
          data-cy="forgot-password__main-container"
          class="mx-auto grid justify-center items-center h-[calc(100%-80px)] w-full"
        >
          <section
            class="forgot-password__form bg-white rounded-xl overflow-hidden"
          >
            <h2
              data-cy="forgot-password__form-title"
              class="text-green-800 text-[21px] font-medium font-roboto px-6 py-4 leading-[34px] text-center"
            >
              {{ formTitle }}
            </h2>

            <div
              v-if="error"
              class="px-6"
            >
              <JdsSectionMessage
                :show="!!error.type"
                variant="error"
                dismissible
                :message="error.message"
                class="mb-5"
                data-cy="forgot-password__error-message"
                @click:close="clearErrorMessage"
              />
            </div>
            <ResetPasswordSuccess v-if="isChangePasswordSuccess" />
            <ResetPasswordForm
              v-else
              :error="error"
              @password-strength="passwordStrength = $event"
              @password="password = $event"
              @password-confirmation="passwordConfirmation = $event"
            />
            <div class="flex justify-center py-4 px-6 bg-gray-50">
              <BaseButton
                v-if="isChangePasswordSuccess"
                class="bg-green-700 hover:bg-green-800 text-base text-white"
                @click="onLogin"
              >
                Masuk ke Akun
              </BaseButton>
              <BaseButton
                v-else
                class="bg-green-700 hover:bg-green-800 text-base text-white"
                :disabled="!isEnabledSubmit"
                @click="onSubmit"
              >
                <JdsSpinner
                  v-show="isLoading"
                  size="16px"
                />
                Atur Ulang
              </BaseButton>
            </div>
          </section>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import jwtDecode from 'jwt-decode';
import Maintenance from '@/common/components/Maintenance';
import HomeIcon from '@/assets/icons/home.svg?inline';
import ResetPasswordSuccess from '@/components/ResetPassword/Success';
import ResetPasswordForm from '@/components/ResetPassword/Form';
import BaseButton from '@/common/components/BaseButton';

import { isBeforeCurrentDateTime } from '@/common/helpers/date';
import { RepositoryFactory } from '@/repositories/RepositoryFactory';

const userRepository = RepositoryFactory.get('user');

export default {
  name: 'ForgotPassword',
  components: {
    Maintenance,
    HomeIcon,
    ResetPasswordSuccess,
    ResetPasswordForm,
    BaseButton,
  },
  data() {
    return {
      portalJabarUrl: process.env.VUE_APP_PORTAL_JABAR_URL,
      error: {
        type: '',
        message: '',
      },
      password: '',
      passwordConfirmation: '',
      passwordStrength: '',
      isLoading: false,
      isEnabledSubmit: false,
      token: this.$route.query.token,
      formTitle: 'Atur Ulang Kata Sandi',
      isChangePasswordSuccess: false,
    };
  },
  computed: {
    isEnabledForgotPassword() {
      return this.$unleash.isEnabled('CMS-FORGOT-PASSWORD');
    },
  },
  watch: {
    password() {
      if (this.password && this.passwordConfirmation && this.passwordStrength === 'strong' && this.password === this.passwordConfirmation) {
        this.isEnabledSubmit = true;
      } else {
        this.isEnabledSubmit = false;
      }
    },
    passwordConfirmation() {
      if (this.password && this.passwordConfirmation && this.passwordStrength === 'strong' && this.password === this.passwordConfirmation) {
        this.isEnabledSubmit = true;
      } else {
        this.isEnabledSubmit = false;
      }
    },
  },
  mounted() {
    if (!this.token) {
      this.$router.push('/login');
    }
    const decodedToken = jwtDecode(this.token);

    if (decodedToken) {
      const { exp } = decodedToken;

      if (isBeforeCurrentDateTime(exp * 1000)) {
        this.$router.push('/expired-token');
      }
    }
  },
  methods: {
    clearErrorMessage() {
      this.error.type = '';
      this.error.message = '';
      this.isEnabledSubmit = true;
    },
    onSubmit() {
      this.isLoading = true;
      if (!this.error.message) {
        this.changePassword();
      }
      this.isLoading = false;
    },
    async changePassword() {
      try {
        const payload = {
          password: this.password,
        };
        const { status } = await userRepository.changeUserPassword(this.token, payload);
        if (status === 200) {
          this.formTitle = 'Atur Ulang Berhasil!';
          this.isChangePasswordSuccess = true;
        }
      } catch (error) {
        const { status } = error.response;
        if (status === 409) {
          this.error.type = 'error';
          this.error.message = 'Anda tidak dapat menggunakan kata sandi lama. Silakan pilih yang baru.';
          this.isEnabledSubmit = false;
        } else {
          this.$router.push('/expired-token');
        }
      }
    },
    onLogin() {
      this.$router.push('/login');
    },
  },
};
</script>

<style scoped>
.forgot-password__form {
  min-width: 454px;
}
</style>
