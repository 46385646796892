var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"password-confirmation-form px-6 pt-3"},[_c('p',{staticClass:"text-sm font-lato text-blue-gray-800 py-2 mb-3",attrs:{"data-cy":"forgot-password__form-subtitle"}},[_vm._v(" Buat kata sandi baru Anda. ")]),_c('div',{staticClass:"flex flex-col gap-1 mb-4"},[_c('label',{staticClass:"text-gray-800 text-[15px]",attrs:{"for":"password","data-cy":"forgot-password__password-label"}},[_vm._v(" Kata Sandi ")]),_c('div',{class:{
        'border rounded-lg overflow-hidden flex items-stretch focus-within:border-green-700': true,
        'border-gray-500': !_vm.isError,
        'border-red-500': _vm.errorType === 'password' || _vm.errorType === 'error'
      }},[_c('div',{staticClass:"bg-gray-100 p-2 border-r border-gray-200 flex justify-center items-center"},[_c('KeyIcon',{staticClass:"fill-gray-700",attrs:{"width":"16","height":"16"}})],1),((_vm.passwordInputType)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],ref:"password-input",class:{
          'text-sm w-full flex placeholder:text-gray-600 p-2 bg-white focus:outline-none': true,
          'cursor-not-allowed': _vm.isError,
        },attrs:{"id":"password","data-cy":"forgot-password__password-input","placeholder":"Masukkan kata sandi","disabled":_vm.isError,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.password)?_vm._i(_vm.password,null)>-1:(_vm.password)},on:{"input":_vm.onPasswordInput,"blur":_vm.closePasswordTooltip,"focus":_vm.openPasswordTooltip,"change":function($event){var $$a=_vm.password,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.password=$$a.concat([$$v]))}else{$$i>-1&&(_vm.password=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.password=$$c}}}}):((_vm.passwordInputType)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],ref:"password-input",class:{
          'text-sm w-full flex placeholder:text-gray-600 p-2 bg-white focus:outline-none': true,
          'cursor-not-allowed': _vm.isError,
        },attrs:{"id":"password","data-cy":"forgot-password__password-input","placeholder":"Masukkan kata sandi","disabled":_vm.isError,"type":"radio"},domProps:{"checked":_vm._q(_vm.password,null)},on:{"input":_vm.onPasswordInput,"blur":_vm.closePasswordTooltip,"focus":_vm.openPasswordTooltip,"change":function($event){_vm.password=null}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],ref:"password-input",class:{
          'text-sm w-full flex placeholder:text-gray-600 p-2 bg-white focus:outline-none': true,
          'cursor-not-allowed': _vm.isError,
        },attrs:{"id":"password","data-cy":"forgot-password__password-input","placeholder":"Masukkan kata sandi","disabled":_vm.isError,"type":_vm.passwordInputType},domProps:{"value":(_vm.password)},on:{"input":[function($event){if($event.target.composing)return;_vm.password=$event.target.value},_vm.onPasswordInput],"blur":_vm.closePasswordTooltip,"focus":_vm.openPasswordTooltip}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isPasswordIconVisible),expression:"isPasswordIconVisible"}],staticClass:"p-2 flex justify-center items-center cursor-pointer",attrs:{"data-cy":"forgot-password__password-visibility"},on:{"click":_vm.togglePasswordInputVisibility}},[_c('JdsIcon',{attrs:{"name":_vm.passwordIconName,"size":"16px"}})],1)])]),_c('PasswordTooltip',{staticClass:"z-10",attrs:{"show":_vm.isTooltipPasswordOpen,"password-strength":_vm.passwordStrength}}),_c('div',{staticClass:"flex flex-col gap-1 mb-4"},[_c('label',{staticClass:"text-gray-800 text-[15px]",attrs:{"for":"password-confirmation","data-cy":"forgot-password__password-confirmation-label"}},[_vm._v(" Ulangi Kata Sandi ")]),_c('div',{class:{
        'border rounded-lg overflow-hidden flex items-stretch focus-within:border-green-700': true,
        'border-gray-500': !_vm.isError,
        'border-red-500': _vm.errorType === 'password-confirmation' || _vm.errorType === 'error'
      }},[_c('div',{staticClass:"bg-gray-100 p-2 border-r border-gray-200 flex justify-center items-center"},[_c('KeyIcon',{staticClass:"fill-gray-700",attrs:{"width":"16","height":"16"}})],1),((_vm.passwordConfirmationInputType)==='checkbox')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.passwordConfirmation),expression:"passwordConfirmation"}],ref:"password-confirmation-input",class:{
          'text-sm w-full flex placeholder:text-gray-600 p-2 bg-white focus:outline-none': true,
          'cursor-not-allowed': _vm.isError
        },attrs:{"id":"password-confirmation","data-cy":"forgot-password__password-confirmation-input","placeholder":"Masukkan kata sandi","disabled":_vm.isError,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.passwordConfirmation)?_vm._i(_vm.passwordConfirmation,null)>-1:(_vm.passwordConfirmation)},on:{"input":_vm.onPasswordConfirmationInput,"change":function($event){var $$a=_vm.passwordConfirmation,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.passwordConfirmation=$$a.concat([$$v]))}else{$$i>-1&&(_vm.passwordConfirmation=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.passwordConfirmation=$$c}}}}):((_vm.passwordConfirmationInputType)==='radio')?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.passwordConfirmation),expression:"passwordConfirmation"}],ref:"password-confirmation-input",class:{
          'text-sm w-full flex placeholder:text-gray-600 p-2 bg-white focus:outline-none': true,
          'cursor-not-allowed': _vm.isError
        },attrs:{"id":"password-confirmation","data-cy":"forgot-password__password-confirmation-input","placeholder":"Masukkan kata sandi","disabled":_vm.isError,"type":"radio"},domProps:{"checked":_vm._q(_vm.passwordConfirmation,null)},on:{"input":_vm.onPasswordConfirmationInput,"change":function($event){_vm.passwordConfirmation=null}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.passwordConfirmation),expression:"passwordConfirmation"}],ref:"password-confirmation-input",class:{
          'text-sm w-full flex placeholder:text-gray-600 p-2 bg-white focus:outline-none': true,
          'cursor-not-allowed': _vm.isError
        },attrs:{"id":"password-confirmation","data-cy":"forgot-password__password-confirmation-input","placeholder":"Masukkan kata sandi","disabled":_vm.isError,"type":_vm.passwordConfirmationInputType},domProps:{"value":(_vm.passwordConfirmation)},on:{"input":[function($event){if($event.target.composing)return;_vm.passwordConfirmation=$event.target.value},_vm.onPasswordConfirmationInput]}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isPasswordConfirmationIconVisible),expression:"isPasswordConfirmationIconVisible"}],staticClass:"p-2 flex justify-center items-center cursor-pointer",attrs:{"data-cy":"forgot-password__password-confirmation-visibility"},on:{"click":_vm.togglePasswordConfirmationInputVisibility}},[_c('JdsIcon',{attrs:{"name":_vm.passwordConfirmationIconName,"size":"16px"}})],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }