<template>
  <section class="password-confirmation-form px-6 pt-3">
    <p
      data-cy="forgot-password__form-subtitle"
      class="text-sm font-lato text-blue-gray-800 py-2 mb-3"
    >
      Buat kata sandi baru Anda.
    </p>

    <div class="flex flex-col gap-1 mb-4">
      <label
        for="password"
        data-cy="forgot-password__password-label"
        class="text-gray-800 text-[15px]"
      >
        Kata Sandi
      </label>
      <div
        :class="{
          'border rounded-lg overflow-hidden flex items-stretch focus-within:border-green-700': true,
          'border-gray-500': !isError,
          'border-red-500': errorType === 'password' || errorType === 'error'
        }"
      >
        <div class="bg-gray-100 p-2 border-r border-gray-200 flex justify-center items-center">
          <KeyIcon
            width="16"
            height="16"
            class="fill-gray-700"
          />
        </div>
        <input
          id="password"
          ref="password-input"
          v-model="password"
          data-cy="forgot-password__password-input"
          :type="passwordInputType"
          placeholder="Masukkan kata sandi"
          :disabled="isError"
          :class="{
            'text-sm w-full flex placeholder:text-gray-600 p-2 bg-white focus:outline-none': true,
            'cursor-not-allowed': isError,
          }"
          @input="onPasswordInput"
          @blur="closePasswordTooltip"
          @focus="openPasswordTooltip"
        >
        <div
          v-show="isPasswordIconVisible"
          class="p-2 flex justify-center items-center cursor-pointer"
          data-cy="forgot-password__password-visibility"
          @click="togglePasswordInputVisibility"
        >
          <JdsIcon
            :name="passwordIconName"
            size="16px"
          />
        </div>
      </div>
    </div>
    <PasswordTooltip
      :show="isTooltipPasswordOpen"
      :password-strength="passwordStrength"
      class="z-10"
    />

    <div class="flex flex-col gap-1 mb-4">
      <label
        for="password-confirmation"
        data-cy="forgot-password__password-confirmation-label"
        class="text-gray-800 text-[15px]"
      >
        Ulangi Kata Sandi
      </label>
      <div
        :class="{
          'border rounded-lg overflow-hidden flex items-stretch focus-within:border-green-700': true,
          'border-gray-500': !isError,
          'border-red-500': errorType === 'password-confirmation' || errorType === 'error'
        }"
      >
        <div class="bg-gray-100 p-2 border-r border-gray-200 flex justify-center items-center">
          <KeyIcon
            width="16"
            height="16"
            class="fill-gray-700"
          />
        </div>
        <input
          id="password-confirmation"
          ref="password-confirmation-input"
          v-model="passwordConfirmation"
          data-cy="forgot-password__password-confirmation-input"
          :type="passwordConfirmationInputType"
          placeholder="Masukkan kata sandi"
          :disabled="isError"
          :class="{
            'text-sm w-full flex placeholder:text-gray-600 p-2 bg-white focus:outline-none': true,
            'cursor-not-allowed': isError
          }"
          @input="onPasswordConfirmationInput"
        >
        <div
          v-show="isPasswordConfirmationIconVisible"
          class="p-2 flex justify-center items-center cursor-pointer"
          data-cy="forgot-password__password-confirmation-visibility"
          @click="togglePasswordConfirmationInputVisibility"
        >
          <JdsIcon
            :name="passwordConfirmationIconName"
            size="16px"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import KeyIcon from '@/assets/icons/key.svg?inline';
import PasswordTooltip from '@/common/components/PasswordTooltip';

export default {
  name: 'ResetPasswordForm',
  components: {
    KeyIcon,
    PasswordTooltip,
  },
  props: {
    error: {
      type: Object,
      default: () => ({ type: '', message: '' }),
    },
  },
  data() {
    return {
      loading: false,
      password: '',
      passwordConfirmation: '',
      isPasswordIconVisible: false,
      isPasswordInputVisible: false,
      isPasswordConfirmationIconVisible: false,
      isPasswordConfirmationInputVisible: false,
      isTooltipPasswordOpen: false,
      passwordStrength: {
        type: '',
        label: '',
      },
    };
  },
  computed: {
    passwordInputType() {
      return this.isPasswordInputVisible ? 'text' : 'password';
    },
    passwordConfirmationInputType() {
      return this.isPasswordConfirmationInputVisible ? 'text' : 'password';
    },
    passwordIconName() {
      return this.isPasswordInputVisible ? 'eye-off' : 'eye';
    },
    passwordConfirmationIconName() {
      return this.isPasswordConfirmationInputVisible ? 'eye-off' : 'eye';
    },
    isError() {
      return !!this.error.message;
    },
    errorType() {
      return this.error.type;
    },
    errorMessage() {
      return this.error.message;
    },
    isLoading() {
      return this.loading;
    },
    isValidInput() {
      return this.password !== '';
    },
  },
  watch: {
    password(value) {
      this.setPasswordIconVisibility(this.password !== '');
      this.$emit('password', this.password);

      this.setPasswordStrength(this.checkPasswordStrength(this.password));
      this.$emit('password-strength', this.passwordStrength.type);

      if (value) {
        this.openPasswordTooltip();
      } else {
        this.closePasswordTooltip();
      }
    },
    passwordConfirmation() {
      this.setPasswordConfirmationIconVisibility(this.passwordConfirmation !== '');
      this.$emit('password-confirmation', this.passwordConfirmation);
    },
  },
  methods: {
    onPasswordInput(event) {
      this.password = event.target.value.replace(/\s/g, ''); // Menghapus spasi
    },
    onPasswordConfirmationInput(event) {
      this.passwordConfirmation = event.target.value.replace(/\s/g, ''); // Menghapus spasi
    },
    setPasswordIconVisibility(value) {
      this.isPasswordIconVisible = value;
    },
    setPasswordConfirmationIconVisibility(value) {
      this.isPasswordConfirmationIconVisible = value;
    },
    togglePasswordInputVisibility() {
      this.setPasswordInputVisibility(!this.isPasswordInputVisible);
    },
    togglePasswordConfirmationInputVisibility() {
      this.setPasswordConfirmationInputVisibility(!this.isPasswordConfirmationInputVisible);
    },
    setPasswordInputVisibility(value) {
      this.isPasswordInputVisible = value;
    },
    setPasswordConfirmationInputVisibility(value) {
      this.isPasswordConfirmationInputVisible = value;
    },
    openPasswordTooltip() {
      this.isTooltipPasswordOpen = true;
    },
    closePasswordTooltip() {
      this.isTooltipPasswordOpen = false;
    },
    setPasswordStrength(type) {
      switch (type) {
        case 'low':
          this.passwordStrength = {
            type: 'low',
            label: 'tidak cukup kuat',
          };
          break;
        case 'medium':
          this.passwordStrength = {
            type: 'medium',
            label: 'cukup kuat',
          };
          break;
        case 'strong':
          this.passwordStrength = {
            type: 'strong',
            label: 'sangat kuat',
          };
          break;
        default:
          this.passwordStrength = {
            type: '',
            label: '',
          };
      }
    },
    checkPasswordStrength(string) {
      // contain at least 1 lowercase alphabetical character
      const isContainLowerCase = /(?=.*[a-z])/.test(string);
      // contain at least 1 uppercase alphabetical character
      const isContainUpperCase = /(?=.*[A-Z])/.test(string);
      // contain at least 1 numeric character
      const isContainNumber = /(?=.*[0-9])/.test(string);
      // contain at least 1 special character
      const isContainSymbol = /(?=.*[!@#$%^&*])/.test(string);

      if (string.length >= 8 && isContainLowerCase && isContainUpperCase && isContainNumber && isContainSymbol) {
        return 'strong';
      }

      if (string.length >= 6 && (isContainLowerCase || isContainUpperCase) && isContainNumber) {
        return 'medium';
      }

      return 'low';
    },
  },
};
</script>

<style>
.password-confirmation-form.jds-section-message {
  gap: 14px;
  padding: 8px;
  color: #212121;
}
.password-confirmation-form .jds-section-message__content__text {
  font-size: 12px;
  line-height: 18px;
}
</style>
