<template>
  <section class="password-confirmation-form px-6 pt-3">
    <div class="flex flex-col justify-center items-center gap-2 my-6">
      <div class="w-[124px] h-[101px] mb-4">
        <img
          src="@/assets/images/key-success.svg"
          alt="Ilustrasi kata sandi berhasil disimpan"
          width="124"
          height="101"
        >
      </div>
      <p class="text-blue-gray-800 text-center font-lato text-sm">
        Kata sandi baru berhasil disimpan. <br>
        Silakan masuk menggunakan e-mail dan kata sandi baru.
      </p>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ResetPasswordSuccess',
};
</script>
